import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Contact" />
    <div className="intro">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className='col-lg-4 col-3'></div>
          <div className="col-lg-8 col-9">
            <h1>Contact</h1>
            <p>Let us know what you think or reach out if you have questions!</p>
          </div>
        </div>
      </div>
    </div>
    <div className='music-notes-background'>
      <div className='container pt-6 pb-6'>
        <div className='row d-flex justify-content-center '>
          <div className='col-lg-6 col-md-8 col-sm-10 col-10 p-2 p-sm-2 p-md-2 p-lg-2 p-xl-2' style={{backgroundColor: '#d2ecefe6', borderRadius: '4px', border: '1px solid #a5b9bb' }}>
            <form action="https://getform.io/f/cf568246-4d32-48ed-a4c0-0ce61bc1de4a" method="POST">
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input name="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
              </div>
              <div class="form-group">
                <label for="nameInput">Name</label>
                <input type="text" class="form-control" id="nameInput" placeholder="Name" name="name"  />
              </div>
              <div class="form-group">
                <label for="nameInput">Message</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="message" placeholder="Message" />
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
